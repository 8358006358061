import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import { IconButton, Tooltip } from "@material-ui/core";


class WorkplanFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        const filter = { ...this.props.workplanData.filter };
        filter[name] = value || "";
        this.props.setFilter(filter);
    }

    syncWorkplanWithErisite = () => {
        const filter = this.props.workplanData.filter ;
        const ErisiteWorkplanData = filter["erisiteWorkplanId"]
        const initialState = new Date();  
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/SyncErisiteWorkplanData", {ErisiteWorkplanId : ErisiteWorkplanData })
                .then(response => {
                    this.getWorkplans(1,30)
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }
    getWorkplans = (page, pageSize) => {
        const filter = this.props.workplanData.filter;

        
        delete filter["page"];
        delete filter["pageSize"];

        if (!filter || Object.values(filter).every(x => (!x || x === ""))) {
            toast.error("Please, fill any field to get related workplans", { containerId: "WS", position: toast.POSITION.TOP_CENTER });
            return;
        }

        const initialState = new Date();    
        filter.page = page;
        filter.pageSize = pageSize;
        filter.id = parseInt(filter.id ?? "0");        
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetFilteredWorkplans", filter)
                .then(response => {
                    this.props.setFilteredWorkplans({ searchedData: [], data: [], totalCount: 0 });
                    const data = [...response.data.data];
                    WorkplanHelper.mapWorkplans(data);
                    
                    this.setState({ isLoading: false }, () => {
                        this.props.setFilteredWorkplans({ searchedData: data, data, totalCount: response.data.totalCount, initialState: initialState.getTime() });
                        
                    });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    render() {
        return (<EuiRow>
            
            <EuiTextField className="m-1 w-10" fullwidth id="workplanIdFilter" name="id" label="Workplan ID (=)"
                value={this.props.workplanData.filter.id || ""} onChange={(e) => this.handleChange(e)}
                //suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: {value:"", name: "id"}})}
            />
            
            {/* <EuiTextField className="m-1 w-8" fullwidth id="erisiteWorkplanIdFilter" name="erisiteWorkplanId" label="Erisite Workplan ID (=)" 
                value={this.props.workplanData.filter.erisiteWorkplanId || ""} onChange={(e) => this.handleChange(e)}
            //    suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "erisiteWorkplanId" } })}
            /> */}
            
            <div style={{display : 'flex' , alignItems : 'end'}}>
            <EuiTextField className="m-1 w-9" fullwidth id="erisiteWorkplanIdFilter" name="erisiteWorkplanId" label="Erisite Workplan ID (=)" 
                value={this.props.workplanData.filter.erisiteWorkplanId || ""} onChange={(e) => this.handleChange(e)}
            //    suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "erisiteWorkplanId" } })}
            />
            <Tooltip  className="m-1 w-1" id="rotate" title="Sync Workplan from Erisite">
                        {/* <IconButton  className= {this.state.isLoading ? "disabled" : "colorPrimary"} style={{ marginRight: 0 }} aria-label="rotate" id="syncworkplan" onClick={() => this.syncWorkplanWithErisite()}>
                            <i style={{ fontSize: 30, color: "#000000" }} className="icon icon-download-save" />
                        </IconButton> */}
                        <button className={"btn  m-1 " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.syncWorkplanWithErisite()}>Sync</button>
                    </Tooltip>
            </div>
            <EuiTextField className="m-1 w-10" fullwidth id="wpAssignmentIdFilter" name="wpAssignmentId" label="WP Assignment ID (=)" 
                value={this.props.workplanData.filter.wpAssignmentId || ""} onChange={(e) => this.handleChange(e)}
                //suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "wpAssignmentId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="workplanNameFilter" name="name" label="Workplan Name (*)" 
                value={this.props.workplanData.filter.name || ""} onChange={(e) => this.handleChange(e)}
                //suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "name" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="customerSiteIdFilterWorkplan" name="customerSiteId" label="Customer Site ID (*)"
                value={this.props.workplanData.filter.customerSiteId || ""} onChange={(e) => this.handleChange(e)}
                //suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "customerSiteId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="projectNameFilter" name="projectName" label="Project Name (*)" 
                value={this.props.workplanData.filter.projectName || ""} onChange={(e) => this.handleChange(e)}
                //suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "projectName" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="siteNameFilter" name="siteName" label="Site Name (*)" 
                value={this.props.workplanData.filter.siteName || ""} onChange={(e) => this.handleChange(e)}
                //suffix={<div className="suffix icon-inside"><i className="icon icon-filter"></i></div>}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "siteName" } })}
            />            
            <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.getWorkplans(1, 30)}>Search</button>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan });
const mapDispatchToProps = dispatch => {
    return {
        setFilteredWorkplans: (filteredWorkplans) => dispatch({ type: "SET_FILTERED_WORKPLANS", payload: filteredWorkplans }),
        setFilter: (filter) => dispatch({ type: "SET_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkplanFilter); 