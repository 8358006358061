import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiAutocompleteSelect from "../../../eui-components/select/autocompleteselect";
import EuiListBuilder from "../../../eui-components/listbuilder";
import { useEffect } from "react";
import EuiNewMultiSelect from "../../../eui-components/select/newmultiselect";
import EuiGenericTree from "../../../eui-components/genericTree";
import EuiLoading from "../../../eui-components/loading";
import { sort } from "d3";

const Role2Content = (props) => {

    const countries = useSelector(state => state.user.countries);
    const roleEditMod = useSelector(state => state.userAdmin.roleEditMod);
    const userId = useSelector(state => state.user.userData.userId);
    const [isLoading,setIsLoading] = useState(false)
    const [filterValue, setFilterValue] = useState('')
    const [aspCompanies, setAspCompanies] = useState(countries?.find(item => item.value === props.selectedRoleItems?.[0]?.countryId ?? 0)?.aspCompanies || []);
    var selectedAspCompaniesIDs = (props.selectedRoleItems || []).map(m=>m.aspCompanyId)
    const [countryId, setCountryId] = useState(props.selectedRoleItems?.[0]?.countryId ?? 0);
    const [selectedCountries, setSelectedCountries] = useState(countries?.filter(c => props.selectedRoleItems?.map(s => s?.countryId)?.includes(c.value)) || []);

    const dispatch = useDispatch();

    var [treeDataBackup,setTreeBackup] = useState([])
    const [tree, setTreeData] = useState((countries.sort((a, b) => (a.text || "").localeCompare(b.text || "")).slice(1).map((item)=>{ 
        let properties = { "state": "opened", "element": item.text, "subList": (item?.aspCompanies || []) ,"id": item.value };
        return properties
    }) || []))
    useEffect(()=>{
       const ss = []
       ss.sort()
       setIsLoading(true)
       const countriesdata = countries.sort((a, b) => (a.text || "").localeCompare(b.text || "")).slice(1).map((item)=>{ 
        let properties = { "state": "opened", "element": item.text, "subList": (item?.aspCompanies || []).map((sub)=>{return {...sub , checked:selectedAspCompaniesIDs.includes(sub.value)}}), "id": item.value };
        return properties
    })
     setTreeData(countriesdata)
     setTreeBackup(countriesdata)
        
    },[])
    useEffect(()=>{
        setIsLoading(false)
    },[tree])
   const  intiTreeData = () => {
      return  countries.map((item)=>{ 
                let properties = { "state": "opened", "element": item.text, "subList": (item?.aspCompanies || []).map((sub)=>{return {...sub , checked:selectedAspCompaniesIDs.includes(sub.value)}}), "id": item.value };
                return properties
            })
    }
    // useEffect(() => {
        
        
    //     if ((props.selectedRoleItems ?? []).length === 0 && props.selectedAction === 0) {
    //         setCountryId(0);
    //         setAspCompanies([]);
    //     }
    // }, [props.selectedRoleItems]);
    useEffect(() => {
      
    }, [aspCompanies]);

    const handleChange = (e, selectedItems , nameValue = null) => {
        const target = e.target;
        const value = target.value;
        const name =  nameValue ? nameValue : target.name;

        
        if (name === "countryId") {
            debugger;
          //  setCountryId(value);
          const selectedCountryItems = countries.filter(c => selectedItems.includes(c.text.trim()));
            
            
            const countryIds = selectedCountryItems.map(s => s.value);
            //If country removal happen then trigger custom event
          //  const element = document.querySelector("#" + "multiSelect_" + props.selectedRole.role);
           // element.dispatchEvent(new CustomEvent("change", { detail: { selectedCountryIds: countryIds } }));
           // const mapCustomers = [...customers];
            // mapCustomers.map(cus => {
            //     cus.hidden = !countryIds.includes(cus.countryId);
            // });
            //setCustomers(mapCustomers);
            
            //const selectedCountries = countries?.filter(item => countryIds.includes(item.value)) || [];
         //   var companies = [];
           // var companiesss =  [[]]
        
         const companiesarray =   selectedCountryItems.map(m=>m?.aspCompanies || [])
         const companies = [].concat(...companiesarray)
          //const companies = countries?.filter(item => countryIds.includes(item.value))?.aspCompanies || [];
            setSelectedCountries(selectedCountryItems);
            //setAspCompanies([]).c
            setAspCompanies(companies);
        } else {
            addSelectedAspCompanies(e, selectedItems);
        }
    }

    const addSelectedAspCompanies = (e, selectedItems) => {
        if (selectedItems) {
            const mappedItems = selectedItems.map(c => {
                const aspCompany = aspCompanies && aspCompanies.find(item => item.text?.trim() === c.trim());
                aspCompany.aspCompanyId = aspCompany.value;
                aspCompany.id = 0;
                aspCompany.createdAt = new Date();
                aspCompany.updatedAt = new Date();
                aspCompany.createUserId = parseInt(userId);
                return aspCompany;
            });
            
            props.setSelectedRoleItems(mappedItems);
        }
    }
    const onTreeItemClicked = (e) => {
        const data = [...tree];
        const item = data.find(item => item.id == e);
        item['state'] = (item.state == "opened" ? "closed" : "opened");
        setTreeData(data)
    }
    const selectedAspCompany = (id, element) => {
 //debugger;
      // const data = {...tree}
      // const newList = [];
      
       var checkedvalues = []
        // tree.map(e => {
        //     if (e.element == element) {
        //         e.subList.map(s => {
        //             if (s.value == id) {
        //              s.state = (!s.state) ?? false  
        //              s.checked = !s.checked
        //             }
        //             // if(s.checked) {
        //             //         const aspCompany = s;
        //             //         aspCompany.aspCompanyId = aspCompany.value;
        //             //         aspCompany.id = 0;
        //             //         aspCompany.createdAt = new Date();
        //             //         aspCompany.updatedAt = new Date();
        //             //         aspCompany.createUserId = parseInt(userId);
        //             //         checkedvalues.push(aspCompany)
                          
                        
        //             // }
        //             // else {

        //             // }
                    
        //         })
        //     }
           
        //     newList.push(e);
        // });
        // setTreeData(newList)
   
 // Update original state
 const updatedOriginalState = treeDataBackup.map(item => ({
    ...item,
    subList: item.subList.map(company =>
      company.value === id && item.element == element
        ? { ...company, checked: !company.checked }
        : company
    )
  }));
  setTreeBackup(updatedOriginalState);

  // Update filtered state with the same checked state from `updatedOriginalState`
  const updatedFilteredState = updatedOriginalState.map(item => ({
    ...item,
    subList: item.subList.filter(company =>
      company.name.toLowerCase().includes(filterValue.toLowerCase())
    )
  }));
  setTreeData(updatedFilteredState);

        var checkedValueCountries = updatedOriginalState.forEach((item)=>{ 
             (item?.subList || []).forEach(s=> {
                if(s.checked) {
                    const aspCompany = s;
                            aspCompany.aspCompanyId = aspCompany.value;
                            aspCompany.id = 0;
                            aspCompany.createdAt = new Date();
                            aspCompany.updatedAt = new Date();
                            aspCompany.createUserId = parseInt(userId);
                            checkedvalues.push(aspCompany)
                    
                }
            
            });
            
        })
        


        props.setSelectedRoleItems(checkedvalues);
    }
    const filterAspCompanies = (e) => {
        // var value = e.target.value;
        // if (value !== "") {
        //     if (value > filterValue.length) {
        //         var testList = tree;
        //         var listTemp = [];
        //         tree.forEach(e => {
        //             var element = {
        //                 ...e,
        //                 subList: e.subList.filter(x => x.text.toUpperCase().includes(value.toUpperCase())),
        //                 state: "opened"
        //             }
        //             if (element.subList.length > 0) {
        //                 listTemp.push(element);
        //             }

        //         })
        //         setFilterValue(value);
        //         setTreeData(listTemp);
        //     }
        //     else {
        //         var testList = [];
        //         var listTemp = [];
        //         tree.forEach(e => testList.push(e));
        //         testList.forEach(e => {
        //             var element = {
        //                 ...e,
        //                 subList: e.subList.filter(x => x.text.toUpperCase().includes(value.toUpperCase())),
        //                 state: "opened"
        //             }

        //             if (element.subList.length > 0) {
        //                 listTemp.push(element);
        //             }

        //         })
        //         setFilterValue(value);
        //         setTreeData(listTemp);
        //     }
        // }
        // else {
        //     setTreeData([...treeDataBackup])
        //     setFilterValue("");
        // }

        var value = e.target.value;
         setFilterValue(value)
         const searchText = value
        if (!searchText.trim()) {
            setTreeData(treeDataBackup); // Reset to original state
            return;
          }
      
          // Filter logic
          const filteredX = treeDataBackup.map(item => ({
            ...item,
            subList: item.subList.filter(company =>
              company.text.toUpperCase().includes(searchText.toUpperCase())
            )
          })).filter(item => item.subList.length > 0);
         
          setTreeData(filteredX);
    }
    return (
        <EuiRow>
           
            {/* <EuiNewMultiSelect
                disabled={!roleEditMod || countryId != 0}
                className="m-1 pr-0"
                divStyle={{ width: "100%" }}
                style={{ maxWidth: "100%", width: "48%", }}
                fullwidth
                label={{ title: "Select country to list companies", style: { fontWeight: 500 } }}
                id="userAdminRole2Countries"
                name="countryId"
                options={countries}
                value={countryId}
                onChange={(e) => handleChange(e)}
                placeholder="---Select Country---"
            /> */}
             {/* <EuiNewMultiSelect
                    className="m-1 "
                    style={{ maxWidth: "100%", width: "47%" }}
                    disabled={!roleEditMod }
                    id={"multiSelect_" + props.selectedRole.role}
                    placeholder="---Select ASP Companies---"
                     name="countryId"
                    options={countries}
                    value={selectedCountries.map(i => i.text)}
                    onChange={(e, options) => handleChange(e, options , "countryId")}
                /> */}
                
                {(props.selectedRole.active || roleEditMod) && 
                <EuiGenericTree
                        id="CountriesTree"
                       //data={tree} //if edit mode show all try , if not then show only selected data.
                       data={roleEditMod ? tree : tree.filter(item => 
                        item.subList.some(sub => sub.checked) // Show only selected items when not in edit mode
                    ).map(item => ({
                        ...item,
                        subList: item.subList.filter(sub => sub.checked), // Include only selected sub-items
                    }))}
                        onFilterChange={filterAspCompanies}
                        viewTree={true}
                        onItemClicked={onTreeItemClicked}
                        onSubItemSelect={ !roleEditMod ? ()=>{}: selectedAspCompany}
                        subListTypeCheckbox = {true}
                        filter={true}
                        SubListStyle = {{paddingLeft: "5px"}}
                    />
                }
                    
                <EuiLoading id = "loader-role-content" isLoading = {isLoading} />
                
            {/* {!props.selectedRole.active ? */}
                {/* <EuiListBuilder
                    addButtonId="role2AddButtonId"
                    removeButtonId="role2RemoveButtonId"
                    id="userAdminRoleTwoListBuilder"
                    availableItem={{ title: "Available ASP Companies", hideSelectAll: true }}
                    assignedItem={{ title: "Assigned ASP Companies", hideSelectAll: true }}
                    data={aspCompanies}
                    preloadedData = {aspCompanies}
        
                    addSelectedItems={(e, selectedItems) => addSelectedAspCompanies(e, selectedItems)}
                />  */}
                {/* :
                 <EuiNewMultiSelect
                    className="m-1 "
                    style={{ maxWidth: "100%", width: "47%" }}
                    disabled={!roleEditMod || countryId === 0}
                    id={"multiSelect_" + props.selectedRole.role}
                    placeholder="---Select ASP Companies---"
                    name="assignedCustomerRole2"
                    options={aspCompanies}
                    value={props.selectedRoleItems.map(i => i.text)}
                    onChange={(e, options) => handleChange(e, options)}
                /> */}
            

        </EuiRow>
    );

}

export default Role2Content;