import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import constants from '../../utils/constants';
import VehicleHelper from '../../utils/vehicle-helper';
import EuiRow from '../../eui-components/row';
import EuiHeader from '../../eui-components/header';
import EuiLoading from '../../eui-components/loading';
import ApiClient from '../../utils/api-client';
import CommonHelper from '../../utils/common-helper';
import EuiAccordion from '../../eui-components/accordion';
import DialogModal from '../../components/dialog'
import PageHelper from '../../utils/page-helper';
import UploadDocuments from '../../components/documentUploadModal/documentsUploader';
import EuiProgressBar from '../../eui-components/progressbar';
import SimpleSlider from '../../components/slider';
import DateUtils from "../../utils/date-utils";
import UserHelper from "../../utils/user-helper";
import VehicleCategoryBox from '../asp-companies/vehicle-management/vehicleInspectionCategory/VehicleCategoryBox';
import ImagesPreview from './ImagesPreview';
import { Logs } from '../../utils/Log';
import { Badge } from '@material-ui/core';
import SasHelper from "../../utils/sas-helper";
import EuiTextField from '../../eui-components/textfield';

const CreateEditInspection = (props) => {
    const users = useSelector(state => state.user.users);
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState([]);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openDiaolgUploadBar, setOpenDialogUploadBar] = useState(false);
    const [percentageArr, setPercentageArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [openImgPreview, setOpenImgPreview] = useState(false);
    const [allDocument, setAllDocument] = useState([]);
    const [inspectionName, setInspectionName] = useState("")
    const [currentSelectedEvidence, setCurrentSelectedEvidence] = useState({});
    const [sasToken, setSasToken] = useState({});

    
    
    const [documentViewData, setDocumentViewData] = useState({
        selectedDocument: { id: 0, statusId: 1 },
        imgIndex: 0,
        totalCount: "",
        imgUrlLength: 0,
    });

    const [vehicleCategory, setVehicleCategory] = useState([]);
    const [inspectionCategory, setInspectionCategory] = useState([]);
    const [isOpenVehicleCategory, setIsOpenVehicleCategory] = useState(false);

    
    const mod = useSelector(state => state.vehicle.inspectionMod);
    const vehicle = useSelector(state => state.vehicle);
    var inspection = useSelector(state => state.vehicleInspection);
    const files = useSelector(state => state.vehicleInspection.evidencesToUpload);
    const user = useSelector(state => state.user);
    const inspectionInstance = useMemo(() => vehicle?.vehicleInspections.find(i => i.id === inspection.currentInspection?.id) || {}, [inspection])
    let documentsIntialList = [];
    const createPercentage = (progressEvent, file) => {
        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
        let percentageArray = [...percentageArr];
        const index = percentageArray.indexOf(file);
        percentageArray[index].percentage = percentage;
        setPercentageArray(percentageArray);
    }
    useEffect(() => {
        if (openDiaolgUploadBar) {
            const onProgress = createPercentage;
            PageHelper.uploadItems(files, onProgress, "vehicle-docs").then(uploadedFiles => {
                CommonHelper.createNotification({ title: "Document is uploaded successfully!", icon: "icon-check", timeout: 5000 });
                setOpenDialogUploadBar(false);
                documentsIntialList = uploadedFiles;
                setIsLoading(true)
                addDocuments();
            })
        }
    }, [openDiaolgUploadBar])

    useEffect(() => {
        SasHelper.getSasKey().then((response) => {
            setSasToken(response);
        });
      
      }, []);
const GetAllInspectionsCategory=()=>{
    ApiClient.get("Vehicle/GetAllInspectionsCategory")
    .then(response => {
        if (response.data){
            setInspectionCategory(response.data)
        }
    })
    .catch(error => {
        console.error(error);;
         CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
    });         
}
useEffect(() => {
    if(inspectionCategory.length===0){
        GetAllInspectionsCategory()    }
        }, [inspectionCategory])
    

const GetVehicleInspectionDetails = () => {
        setIsLoading(true);
        setIsLoadingDetail(true);
        let currentInspection = { ...inspection.currentInspection };
        let id = inspection.currentInspection.id

        dispatch({ type: "SET_CURRENT_INSPECTION", payload: {currentInspectionItems:[]} });
        ApiClient.post("Vehicle/GetVehicleInspectionDetails", { id })
        .then(response => {
            if (response.data){
                currentInspection.currentInspectionItems = response.data;
                setIsLoading(false);
                dispatch({ type: "SET_CURRENT_INSPECTION", payload:  currentInspection });
            } 
        })
        .catch(error => {
            setIsLoading(false);
            setIsLoadingDetail(false);
            setOpenUploadDialog(false);
            dispatch({ type: "SET_CURRENT_INSPECTION", payload: {currentInspectionItems:[]} });
            CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
        }).finally(() => {
            setIsLoadingDetail(false);
        });
    }

    const addDocuments = () => {
        if (!documentsIntialList || documentsIntialList.length === 0 ) {
            CommonHelper.createNotification({ title: "No evidence documents have been uploaded", icon: "icon-cross", timeout: 5000 });
            return;
        }
        else {
            const docs = documentsIntialList.map(item => {
                return {
                    documentPath: item.documentPath,
                    documentName: item.title,
                    inspectionChecklistItemId:inspection.selectedItem?.id,
                    vehicleInspectionId: inspection.currentInspection?.id
                }
            });
            const request = [{
                id: inspection.selectedItem?.id,
                modifiedUserId: parseInt(user.userData.id),
                comment: inspection.selectedItem.comment,
                vehicleInspectionId: inspection.currentInspection?.id,
                vehicleInspectionEvidences: docs
            }];
            setOpenUploadDialog(false);
            ApiClient.post("Vehicle/UploadChecklistItemsEvidences", request)
                .then(response => {
                      GetVehicleInspectionDetails()
                     setIsLoading(false);
                }).catch(error => {
                    setOpenUploadDialog(false);
                    setIsLoading(false);
                    dispatch({ type: "SET_INSPECTION_EVIDENCES", payload: [] });
                });
        }
    }
    const getRightActions = () => {
        if (mod === constants.pageMods.ADD) {
            return <div><button className={"btn "} style={{ margin: 5 }}
                onClick={() => dispatch({ type: "CHANGE_INSPECTION_MOD", payload: constants.pageMods.LIST })}
            ><i className="icon icon-cross p-1"></i>Cancel</button>
                <button className={"btn primary "} style={{ margin: 5 }}
                    onClick={createNewInspection}
                ><i className="icon icon-check p-1"></i>Create</button>
            </div>
        }
        else if (mod === constants.pageMods.EDIT || mod === constants.pageMods.VIEW_ONLY) {
            return <div><button className={"btn "} style={{ margin: 5 }}
                onClick={() => dispatch({ type: "CHANGE_INSPECTION_MOD", payload: constants.pageMods.LIST })}
            ><i className="icon icon-arrow-left p-1"></i>Back</button>

            </div>
        }
    }
    const setImageTrack = (slide, item) => {
        const allDocument = item.vehicleInspectionEvidences;
        const imgUrlLength = allDocument.length;
        let imgIndex = allDocument.findIndex(item => item.id === slide.id);
        dispatch({ type: "SET_SELECTED_ITEM", payload: item });
        setDocumentViewData({
            selectedDocument: slide,
            imgIndex,
            totalCount: (imgIndex + 1) + "/" + imgUrlLength,
            imgUrlLength
        });
        setOpenImgPreview(true);
    }
    const selectAndOpenDocument = (document, item) => {
        const allDocument = [...item.vehicleInspectionEvidences?.map(i => {
            let mappedItem = {
                ...document,
                ...i,
                id: i.id,
                posterPath: SasHelper.addSasKey(item.documentPath,sasToken),
                name: i.documentName,
                comment: i.comment,
                inspectionChecklistItemId: i.inspectionChecklistItemId,
                type: 1,
                title: (<p style={{ fontSize: '18px', fontWeight: 'bold', color: "white" }}>{item.checklistItemName}</p>),
                acceptanceStatusId: i.acceptanceStatusId ?? 1,
                uploadDetails: "\n" + "User Created: " + (UserHelper.getUser(users, i.createUserId)?.name ?? ""),
                additionalInfo: "\n" + ", Date time created: " + DateUtils.toDateString(i.createDate, "YYYY-MM-DD HH:mm:ss")
            }
            return mappedItem
        })];
        setAllDocument(allDocument);
        setImageTrack(document, item);
    }
    const getAcceptanceStatusId = (id) => {
        switch (id) {
            case 1:
            case 2:
                return 1;
            case 3:
                return 2;
            case 4:
                return 3;
            default:
                return 1;
        }
    }
    const renderFilePreview = () => {
        const item = { ...inspection.selectedItem }
        
        
        const comments = item.comment ?? "";
        
        return <SimpleSlider
            id="inspectionSlider"
            slideData={allDocument}
            currentSlide={documentViewData.selectedDocument || allDocument[0]}
            initialSlide={documentViewData.imgIndex}
            totalCount={documentViewData.totalCount}
            onNext={nextClick}
            onPrev={prevClick}
            onClose={() => setOpenImgPreview(false)}
            status={PageHelper.getActivityStatus(getAcceptanceStatusId(documentViewData.selectedDocument.statusId))}
            comment={documentViewData.selectedDocument.comment}
            setImageTrack={(slide) => setImageTrack(slide, item)}
            type={3}
        />
    }
    const prevClick = () => {
        const imgIndex = documentViewData.imgIndex === 0 ? documentViewData.imgUrlLength - 1 : documentViewData.imgIndex - 1;
        const selectedDocument = allDocument[imgIndex];
        const totalCount = (imgIndex + 1) + "/" + documentViewData.imgUrlLength;
        setDocumentViewData({
            selectedDocument: selectedDocument,
            imgIndex,
            totalCount,
            imgUrlLength: allDocument.length
        });
    }
    const nextClick = () => {
        const imgIndex = documentViewData.imgIndex === documentViewData.imgUrlLength - 1 ? 0 : documentViewData.imgIndex + 1;
        const selectedDocument = allDocument[imgIndex];
        const totalCount = (imgIndex + 1) + "/" + documentViewData.imgUrlLength;
        setDocumentViewData({
            selectedDocument: selectedDocument,
            imgIndex,
            totalCount,
            imgUrlLength: allDocument.length
        });
    }
    const getDocuments = (checklistItem) => {
        
        if ((checklistItem.vehicleInspectionEvidences || []).length === 0) {
            return [];
        }
        checklistItem.vehicleInspectionEvidences.map(item => {
            item.name = item.documentName;
            item.posterPath = SasHelper.addSasKey(item.documentPath,sasToken);
            item.acceptanceStatusId = getAcceptanceStatusId(item.statusId);
            item.comment = item.comment;
            item.additionalInfo = "User Created: " + UserHelper.getUser(users, item.createUserId?.name) + "\n" + "Date time created: " + DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:mm:ss");
            item.uploadDetails = "User Created: " + UserHelper.getUser(users, item.createUserId?.name) + "\n" + "Date time created: " + DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:mm:ss");
        });
        return checklistItem.vehicleInspectionEvidences;
    }
    const setSelectedItemsFun = (e, type) => {
        const detail = e.detail;
        const data = detail.data;
        
        const currentSelectedItems = [...selectedItems];
        if (!currentSelectedItems.length)
            dispatch({ type: "SET_INSPECTION_ITEMS_SELECTED_LIST", payload: [] });

        if (type === "add") {
            currentSelectedItems.push(data);
            setSelectedItems([...currentSelectedItems]);
        }
        else if (type === "remove") {
            
            setSelectedItems(selectedItems.filter(item => item.id !== data.id));
            currentSelectedItems.filter(item => item.id !== data.id);
        }
        dispatch({ type: "SET_INSPECTION_ITEMS_SELECTED_LIST", payload: currentSelectedItems });

        
        
    }

    const createVehicleInspectionsLog= (response) =>{

        const logInfo={};
        const data={};
        logInfo.LogName= "Inspection" 
        logInfo.userId=parseInt(user?.userData?.id);
        logInfo.LogObjectId=vehicle?.currentVehicle?.id;// id of Inspection
        logInfo.LogAction=VehicleHelper.vehicleAction.CREATED ;
        logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];
        data.Name='create Inspection'
        data.createDatetimeUtc=response.createDatetimeUtc;
          Logs(logInfo,{data})
};

    const createNewInspection = () => {
        if (!inspectionName || inspectionName.length === 0) {
            CommonHelper.createNotification({ title: "Please add the inspection name!", icon: "icon-cross", timeout: 5000 });
            return;
        }
    
        const request = {
            vehicleId: vehicle?.currentVehicle?.id,
            createUserId: parseInt(user?.userData?.id),
            inspectionName: inspectionName,
            checklistItemIds: []
        }
        setIsLoading(true)
        ApiClient.post("Vehicle/MonthlyInspectionWithChecklists", request)
            .then(response => {
                if (response.data.errorMessage) {
                    // CommonHelper.createNotification({ title: response.data.errorMessage, icon: "icon-cross", timeout: 5000 });
                    setIsLoading(false);
                }
                else {
                     getVehiclesInspections();
                     createVehicleInspectionsLog(response.data);
                    dispatch({ type: "CHANGE_INSPECTION_MOD", payload: constants.pageMods.LIST });
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);;
            }); 
    }
    const openUploadDialogBox = (item) => {
        setCurrentSelectedEvidence(item)

        dispatch({ type: "SET_SELECTED_ITEM", payload: item })
        setOpenUploadDialog(true);



    }
    const getVehiclesInspections = () => {
        const id = vehicle?.currentVehicle?.id;
        ApiClient.post("Vehicle/GetListOfInspectionsPerVehicle", { id })
            .then(response => {
                dispatch({ type: "SET_VEHICLE_INSPECTIONS_LIST", payload: response.data });
            })
            .catch(error => {
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });
    }
    const uploadDocuments = () => {
        let selectedItem = { ...inspection.selectedItem };
        if (PageHelper.checkFilesSize(files) && !selectedItem.comment) {
            CommonHelper.createNotification({ title: "Upload Failed", icon: "icon-triangle-warning", timeout: 5000 });
            return;
        }
        if (!files || files.length === 0) {
            CommonHelper.createNotification({ title: "No files were selected for uploading!", icon: "icon-cross", timeout: 5000 });
            setOpenUploadDialog(false);
            return;
        }
        else {
            PageHelper.getCoordinates();
            setOpenDialogUploadBar(true);
            setPercentageArray(PageHelper.initializePercentageArr(files))
        }
    }
    const getRequiredPhoto = (item) =>
    {
        var approvedCount = item.vehicleInspectionEvidences.filter(v => v.statusId == 1 ||  v.statusId == 2 ).length
        return approvedCount > item.minPhoto ? 0 : approvedCount - item.minPhoto;
    }
    const renderChecklists = () => {
        let accordionItems = [];
        let outerAccordionItems = [];
            accordionItems = inspection?.currentInspection?.currentInspectionItems.map(item => {
                const checklistItem = {
                    categoryId: item.categoryId,
                    id: item.id + item.categoryId,
                    opened: "opened",
                    title:  <div className='mt-2'>{item.checklistItemName}</div>,
                    titleStyle: { fontWeight: 500, padding: 3 },
                    contentStyle: { paddingLeft: 10 },
                    selectable: true,
                    icon: true,
                    titleIcon: (inspectionInstance.statusId === VehicleHelper.vehicleStatusLookup['Under Review'] || inspectionInstance.statusId === VehicleHelper.vehicleStatusLookup['Approved'] || mod === constants.pageMods.VIEW_ONLY ) ?
                        (<></>) :
                        (<div key={item.id}> 

                            <span className="tooltip mr-2" >
                                <Badge badgeContent={ item.vehicleInspectionEvidences?.length} color="primary" >
                                    <i class='icon icon-attach'
                                            style={{ fontSize: 19, bottom:5, right:2, height:20,width:20,position:'relative', paddingLeft: 2 }}></i>
                                </Badge>
                                <span class="message top-end">Total of Evidence Uploaded</span>
                            </span>
                            {item.isMandatory && 
                                <span className="tooltip ml-2">
                                    <Badge badgeContent={getRequiredPhoto(item)} color="error" >
                                        <i className="icon icon-severity-critical"
                                            style={{ fontSize: 19, bottom:5, left:1, height:20,width:20,position:'relative', paddingLeft: 2, color: "#ED0E00" }}></i>
                                    </Badge>
                                    <span className="message top-end">{item.minPhoto}</span>
                                </span>
                            }
                            {VehicleHelper.approvedRoles.includes(user.userData.jobRole) &&
                                <span className="tooltip ml-2">
                                    <i className="icon icon-upload icon-frame clickable p-1 text-lg" onClick={() => openUploadDialogBox(item)}></i>
                                    <span className="message top-end">Upload Evidences</span>
                                </span>
                            }
                        </div>),
                    content: <>
                        <EuiHeader id={"inspectionHeader" + item.id} style={{ width: "100%" }} />
                        <ImagesPreview   
                            sasToken={sasToken}
                            tileData={getDocuments(item)}
                            setIsLoading={setIsLoading}
                            inspection={inspection}
                            inspectionInstanceStatusId ={inspectionInstance.statusId }
                            onClickImage={(document) => selectAndOpenDocument(document, item)}
                            setIsOpenVehicleCategory={setIsOpenVehicleCategory}
                            GetVehicleInspectionDetails={GetVehicleInspectionDetails}
                        />
                    </>,

                };
                
                return checklistItem;
            });
            outerAccordionItems = inspectionCategory?.map(item => {
                const checklistCategory = {
                    id:item.id + " category", 
                    title:item.category,
                    opened: "opened",
                    contentStyle: { paddingLeft: 5 },
                    selectable: true,
                    checklistItem: inspection?.currentInspection?.currentInspection?.filter(i => i.categoryId ===item.id )[0] ,//inspection?.currentInspection?.currentInspectionItems Itemsinspection?.inspectionItemsList
                    content: <EuiAccordion id={"innerchecklistAccordion" + item.id}
                        style={{ width: "100%" }}
                        items={accordionItems?.filter(i => i.categoryId === item.id)}
                    />
                }
                return checklistCategory;

            });
            return(  
            <>
                <VehicleCategoryBox
                    checklistItems={inspection?.currentInspection?.currentInspectionItems  }
                    evidencesToUpload={inspection.evidencesToUpload}
                    selectedCategoryId={inspection.selectedItem?.categoryId||1}
                    outerAccordionItems={outerAccordionItems} 
                    setVehicleCategory={setVehicleCategory}
                    setIsOpenVehicleCategory={setIsOpenVehicleCategory}
                    inspectionCategory={inspectionCategory}
                />
                
                {isOpenVehicleCategory &&   
                   <EuiAccordion 
                        id="inspectionChecklistAccordion"
                        noAutoClose={true}
                        style={{ width: "100%" }}
                        items={vehicleCategory}
                    />
                }
            </>)
    }

    const addComment = (e) => {
        const target = e.target;
        var value = target.value;
        let selectedItem = { ...inspection.selectedItem }
        
        selectedItem.comment = value;
        dispatch({ type: "SET_SELECTED_ITEM", payload: selectedItem });
    }
    const renderDialogEdit = () => {
        return <EuiRow className="w-10">
            {/*<EuiRow className="w-10">*/}
            {/*    <EuiTextField className="w-10" fullwidth id="selectedInspectionComment" name="comment" label="Comments" labelStyle={{ fontSize: "14px" }}*/}
            {/*        value={inspection.selectedItem?.comment} onChange={(e) => addComment(e)} />*/}
            {/*</EuiRow >*/}
            <EuiRow className="w-10">
                <label style={{ fontSize: "14px", color: "gray" }}>Upload Documents</label>
                <UploadDocuments id="inspectionDocumentsDialog" dispatch="SET_INSPECTION_EVIDENCES" columns={{ name: true, description: true }} />
            </EuiRow >

        </EuiRow >
    }
    return (
        
        <>
       {inspectionInstance.overallComment && <span><i style={{color : (inspectionInstance.statusId &&  inspectionInstance.statusId  == 4) ? "red" : "black" , fontWeight: 'bold'}}>Approval/Rejection Comment : </i> {inspectionInstance.overallComment} </span>}
        <EuiRow style={{ width: "1000px" }} key={isLoading} disabled={isLoading ? "disabled" : ""}>
            <EuiLoading id="createEditInspectionLoading" isLoading={isLoading} />
            <EuiHeader title={mod === constants.pageMods.EDIT ? (inspectionInstance.inspectionName || "Inspection Checklist Items") : "Create New Inspection"} style={{ width: "100%" }} id="createEditMonthlyInspectionHeaderId" rightActions={getRightActions()}>
            </EuiHeader>
           

            {!isLoadingDetail && renderChecklists()}
            {openImgPreview ?
                renderFilePreview()
                :
                null}
            <DialogModal
                id="inspectionDocumentsDialog"
                style={{ height: "1000px" }}
                title={currentSelectedEvidence?.checklistItemName} 
                content={renderDialogEdit()}
                buttonName="Save"
                handleClose={() => setOpenUploadDialog(false)}
                onClick={() => uploadDocuments()}
                contentStyle={{ width: "800px", height: "400px" }}
                open={openUploadDialog}
                // disableBackdropClick={true} 

                />
            <DialogModal
                content={percentageArr.map(file => {
                    return <div key={file.name} style={{ width: 500 }}>
                        <label><strong>{file.title}</strong></label>
                        <EuiProgressBar value={file.percentage} />
                    </div>;
                })}
                handleClose={() => setOpenDialogUploadBar(false)}
                id="uploadInspectionDocumentsDialog"
                titleId="titleuploadBarInspectionDocumentsUploadDialog"
                open={openDiaolgUploadBar}
                buttonsVisible="false"
                title="Progress Loading..."
                closeButtonVisible="false"
                key={percentageArr}
                disableBackdropClick={true} />
        </EuiRow>
     
            </>
    );
}

export default CreateEditInspection;